
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import {
  addPatientPayer,
  deletePatientPayer,
  getPatientPayers,
  updatePatientPayer,
} from "@/api/patientPayer.api";
import useVuelidate from "@vuelidate/core";
import { helpers,required } from "@vuelidate/validators";
import PatientPayerComponent from "@/modules/patientPayer/PatientPayerComponent.vue";

export interface Data {
  items: [];
  selectedItem?: {
    id: string;
    effectiveDate: Date;
    name: string;
    payerId: string;
  };
  panel: unknown;
}

export default defineComponent({
  name: "PatientPayerCRUDPage",
  components: { PatientPayerComponent },
  setup() {
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      items: [],
      selectedItem: undefined,
      panel: { facilities: [], patients: [], providers: [] },
    });

    data.selectedItem = undefined;

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Patient Payers");
      await getAll();
    });

    async function switchMode() {
      data.selectedItem = undefined;
    }

    async function getAll() {
      data.items = await getPatientPayers();
    }

    async function addItem() {
      const result = await v$.value.$validate();
      if (result) {
        await addPatientPayer(data.selectedItem);
        await getAll();
      }
    }

    function selectItem(item) {
      data.selectedItem = item;
    }

    async function updateItem() {
      const result = await v$.value.$validate();
      if (result) {
        await updatePatientPayer(data.selectedItem);
      }
    }

    async function deleteItem() {
      await deletePatientPayer(data.selectedItem);
      await getAll();
    }

    function addMode() {
      data.selectedItem = undefined;
    }
    async function itemChanged() {
      await getAll();
    }

    const providerRules = {
      selectedItem: {
        name: { required:helpers.withMessage("Required", required) }, 
        payerId: { required:helpers.withMessage("Required", required) },
        // AB-389
        // responsibility: { required },
        // effectiveDate: { required },
        // termDate: { required },
        releaseOfInformation: { required:helpers.withMessage("Required", required) },
        assignmentOfBenefits: { required:helpers.withMessage("Required", required) },
        patientRelationshipToSubscriber: { required:helpers.withMessage("Required", required) },
        subscriberFirstName: { required:helpers.withMessage("Required", required) },
        subscriberMiddleInitial: { required:helpers.withMessage("Required", required) },
        subscriberLastName: { required:helpers.withMessage("Required", required) },
        subscriberIdNumber: { required:helpers.withMessage("Required", required) },
        subscriberGroupNumber: { required:helpers.withMessage("Required", required) },
        patientId: { required:helpers.withMessage("Required", required) },
      },
    };

    const v$ = useVuelidate(providerRules, data as never);

    return {
      organizationId,
      data,
      addItem,
      selectItem,
      updateItem,
      deleteItem,
      switchMode,
      addMode,
      itemChanged,
      v$,
    };
  },
});
